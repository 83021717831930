import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { dimensions, rawDimensions } from '@maestro/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { UploadedImage } from '../hooks/useUploadImage';
import { Icon } from '../Icon';
import { Textarea } from '../Textarea';
import { useGenerateImageWithAi } from './hooks/useGenerateImageWithAi';

type Props = {
  onImageSelect: (image: UploadedImage) => void;
};

export const CreateWithAi: React.FC<Props> = ({ onImageSelect }) => {
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState('');
  const { generateImage } = useGenerateImageWithAi();
  const [imageUrl, setGeneratedImageUrl] = useState('');

  const onUseClick = () => {
    onImageSelect({ path: imageUrl, height: 1024, width: 683 });
  };

  const onGenerate = () => {
    setIsGenerating(true);
    setGeneratedImageUrl('');
    generateImage(prompt)
      .then(setGeneratedImageUrl)
      .catch(() => setError('Error while generating image'))
      .finally(() => setIsGenerating(false));
  };

  return (
    <FormControl isInvalid={!!error}>
      <AiContainer>
        <Textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          isInvalid={!!error}
        />
        {imageUrl && <Image src={imageUrl} alt="Generated image" />}
        <ButtonGroup>
          {imageUrl && (
            <Button variant="primary" onClick={onUseClick}>
              Use
            </Button>
          )}
          <Button
            variant={imageUrl ? 'default' : 'primary'}
            onClick={onGenerate}
            isDisabled={!prompt}
            isLoading={isGenerating}
            leftIcon={
              <Icon
                name={imageUrl ? 'arrow-counterclockwise' : 'ai-generate'}
                size={rawDimensions.size16}
              />
            }
          >
            {imageUrl ? 'Regenerate' : 'Generate'}
          </Button>
        </ButtonGroup>
      </AiContainer>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

const AiContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: end;
`;

const Image = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  border-radius: ${dimensions.size8};
`;
